/* eslint-disable camelcase */
import { ExecuteResponse } from '@/util/models/ServerBaseEntity'
import { adaptPost, EmptyHttpError, execPostValid, getPost } from '@/util/utils/HttpClient'
import { IValid, vr } from '@/util/utils/validator'

export interface GetUserTagResponse {
  vip1: boolean
  vip2: boolean
}
export interface CheckToolConfigRequest {
  tag: string
}
export interface GetUserConfigItem {
  selected: boolean
  name: string
  remark: string
}
export interface GetUserConfigResponse {
  configs: GetUserConfigItem[]
}
export interface GetToolConfigRequest {
  tool_type: string
}
export interface GetToolConfigResponse {
  tool_type: string
  context: string
}
export class SaveToolConfigRequest extends IValid {
  @vr({ required: true, message: '配置类型不能为空' })
  tool_type: string = ''

  @vr({ required: true, message: '配置内容不能为空' })
  context: string = ''
}
export class ToolApi {
  @adaptPost<GetUserTagResponse>('/vscode/tool/getUserTag', s => s ?? { vip1: false, vip2: false })
  static async getUserTag(request: {}): Promise<GetUserTagResponse> {
    throw new EmptyHttpError()
  }

  @adaptPost<GetUserConfigResponse>('/vscode/tool/getUserConfig', s => s ?? { configs: [] })
  static async getUserConfig(request: {}): Promise<GetUserConfigResponse> {
    throw new EmptyHttpError()
  }

  @adaptPost<GetToolConfigResponse>('/vscode/tool/config/get', s => s ?? {})
  static async getToolConfig(request: GetToolConfigRequest): Promise<GetToolConfigResponse> {
    throw new EmptyHttpError()
  }

  @adaptPost<ExecuteResponse>('/vscode/tool/checkUserTag', s => s ?? { success: false })
  static async checkUserTag(request: CheckToolConfigRequest): Promise<ExecuteResponse> {
    throw new EmptyHttpError()
  }

  @execPostValid('/vscode/tool/config/save')
  static async saveToolConfig(request: SaveToolConfigRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }
}
