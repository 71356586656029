/* eslint-disable camelcase */
import { fromSlot, fromInput } from '@/util/models/admin/fromItem'
import { PageRequest } from '@util/models/ServerBaseEntity'
import type { IdEntity } from '@util/models/ServerIdEntity'
import { adaptPost, EmptyHttpError, execHidePost } from '@util/utils/HttpClient'

export class SavePluginMenuItemRequest {
  source_id: number | undefined
  execute_type: number = 0
  name: string = ''
  remark: string = ''
  command: string = ''
  children: SavePluginMenuItemRequest[] = []
}

export class SavePluginMenuRequest {
  children: SavePluginMenuItemRequest[] = []
}
export class QueryPluginMenusRequest extends PageRequest {
}
export class QueryServerPluginMenusResponse implements IdEntity {
  id: number = 0

  name: string = ''

  remark: string = ''
  source_id: number | undefined
  execute_type: number | undefined

  items: Array<QueryServerPluginMenusResponse> = []
}
export class GetServerMenusResponse implements IdEntity {
  id: number = 0

  @fromInput({ title: '菜单名称' })
  name: string = ''

  parent_id: number | undefined

  @fromSlot({ title: '引用菜单' })
  source_id: number | undefined

  execute_type: number | undefined

  command: string = ''

  remark: string = ''
}

export class UserMenuApi {
  @execHidePost('/vscode/menus/server/saveMenus')
  static async save(request: SavePluginMenuRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @adaptPost<Array<QueryServerPluginMenusResponse>>('/vscode/menus/server/queryMenus', s => s ?? [])
  static async query(request: QueryPluginMenusRequest): Promise<Array<QueryServerPluginMenusResponse>> {
    throw new EmptyHttpError()
  }
}
export const editModel = () => new GetServerMenusResponse()
