/* eslint-disable camelcase */
import { queryText, queryContent, querySlot } from '@/util/models/admin/queryItem'
import { tableDate, tableEditUrl, tableIdRow, tableText, tableIcon, tableEnable, tableButton, tableRowKey, tablePagination, tableSlot } from '@/util/models/admin/tableItem'
import { fromSlot, fromInput, areaInput, fromSwitch } from '@/util/models/admin/fromItem'
import { PageResponse, PageRequest, IdNameRemarkEntity } from '@util/models/ServerBaseEntity'
import type { IdEntity } from '@util/models/ServerIdEntity'
import { adaptPostValid, ApiWinModel, queryPost, EmptyHttpError, execPostValid, ISaveEditRequest, execHidePostValid } from '@util/utils/HttpClient'
import { vr, IValid, ValidIdRequest } from '@/util/utils/validator'
import { getIconClass } from '@/util/utils/iconUtils'
export class SaveMergeRequest extends IValid {
  @vr({ required: true, max: 200, message: '配置名称不能超过200个字符' })
  name: string = ''

  @vr({ required: true, max: 1024, message: '配置备注不能超过1024个字符' })
  remark: string = ''

  @vr({ required: true, min: 0, message: '引擎类型不能为空', type: 'number' })
  target_type: number = 0

  @vr({ required: true, min: 0, message: '配置类型不能为空', type: 'number' })
  item_type: number = 0

  @vr({ required: true, min: 0, message: '公开类型不能为空', type: 'number' })
  auth_type: number = 0

  items: Array<number> = []
}
export class EditMergeRequest extends IValid implements IdEntity {
  @vr({ required: true, min: 0, message: 'ID不能为空', type: 'number' })
  id: number = 0

  @vr({ required: true, max: 200, message: '配置名称不能超过200个字符' })
  name: string = ''

  @vr({ max: 1024, message: '配置备注不能超过1024个字符' })
  remark: string = ''

  @vr({ required: true, min: 0, message: '引擎类型不能为空', type: 'number' })
  target_type: number = 0

  @vr({ required: true, min: 0, message: '配置类型不能为空', type: 'number' })
  item_type: number = 0

  @vr({ required: true, min: 0, message: '公开类型不能为空', type: 'number' })
  auth_type: number = 0

  items: Array<number> = []
}
export class QueryMergeRequest extends PageRequest {
  @queryContent({ loadQuery: true })
  @queryText({ text: '事件名称' })
  name: string = ''

  @querySlot({ title: '引擎类型' })
  target_type: number | undefined

  @querySlot({ title: '配置类型' })
  item_type: number | undefined
}
export class QueryMergeResponse implements IdEntity {
  @tableRowKey()
  @tablePagination()
  @tableButton({
    text: '生成配置',
    val: { position: 'column', width: 80 },
    style: { icon: getIconClass('el-icon-json'), type: 'primary', size: 'small' },
    e: { click: 'generate' },
    check: (row) => row.has_current ?? false
  })
  @tableButton({
    text: '删除',
    val: { width: 90, position: 'column' },
    style: { icon: getIconClass('el-icon-shanchu'), type: 'danger', size: 'small' },
    e: { click: 'remove' },
    pop: {
      title: '是否删除当前项目?'
    },
    check: (row) => row.has_current ?? false
  })
  @tableButton({
    text: '修改',
    val: { width: 90, position: 'column' },
    style: { icon: getIconClass('el-icon-bianji'), size: 'small' },
    e: { click: 'edit' },
    check: (row) => row.has_current ?? false
  })
  @tableButton({
    text: '新增',
    val: { position: 'header' },
    style: { icon: getIconClass('el-icon-xinzeng1'), type: 'primary', size: 'default' },
    e: { click: 'insert' }
  })
  id: number = 0

  @tableEditUrl({ header: '事件名称', e: { click: 'edit' } })
  name: string = ''

  target_type: number = 0
  @tableText({ header: '引擎类型', width: 90 })
  target_name: string = ''

  item_type: number | undefined
  @tableText({ header: '配置类型', width: 90 })
  item_name: string | undefined

  auth_type: number | undefined
  @tableText({ header: '公开类型', width: 90 })
  auth_name: string | undefined

  // 是否自身项目
  has_current: boolean = false

  @tableText({ header: '订阅数量', width: 90 })
  subscribe: number = 0

  // 是否订阅
  @tableSlot({ header: '是否订阅', width: 90 })
  has_subscribe: boolean = false

  @tableDate({ header: '配置时间' })
  merge_time: string = ''

  @tableDate({ header: '修改时间' })
  modify_time: string = ''
}
export class GetMergeResponse implements IdEntity {
  id: number = 0

  @fromInput({ title: '事件名称' })
  name: string = ''

  @fromSlot({ title: '引擎类型' })
  target_type: number | undefined

  @fromSlot({ title: '配置类型' })
  item_type: number | undefined

  @fromSlot({ title: '公开类型' })
  auth_type: number | undefined

  @fromSlot({ title: '配置备注' })
  remark: string = ''

  merge_file?: string
}
export class QueryMergeItemInfoRequest extends PageRequest {
  id: number = 0

  constructor(i: number) {
    super()
    this.id = i
  }
}

export interface QueryUserSubscribeResponse {
  item_type: number
  items: Array<number>
}

export interface GetUserSubscribeResponse {
  merge_id: number
  merge_file: string
}

export class SaveSubscribeRequest extends ValidIdRequest {
  subscribe: boolean = false
}

export class MergeApi {
  @execPostValid('/vscode/merge/save')
  static async save(request: SaveMergeRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/vscode/merge/edit')
  static async edit(request: EditMergeRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/vscode/merge/delete')
  static async delete(request: ValidIdRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/vscode/merge/generate')
  static async generate(request: ValidIdRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/vscode/merge/import')
  static async import(request: SaveMergeRequest[]): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @queryPost<QueryMergeResponse>('/vscode/merge/query')
  static async query(request: QueryMergeRequest): Promise<PageResponse<QueryMergeResponse>> {
    throw new EmptyHttpError()
  }

  @queryPost<IdNameRemarkEntity>('/vscode/merge/getItemsById')
  static async getItemsById(request: QueryMergeItemInfoRequest): Promise<PageResponse<IdNameRemarkEntity>> {
    throw new EmptyHttpError()
  }

  @queryPost<IdEntity>('/vscode/merge/getItemById')
  static async getItemById(request: QueryMergeItemInfoRequest): Promise<PageResponse<IdEntity>> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetMergeResponse>('/vscode/merge/getById', (resp) => resp ?? { id: 0 })
  static async getById(request: ValidIdRequest): Promise<GetMergeResponse> {
    throw new EmptyHttpError()
  }

  @execHidePostValid('/vscode/merge/subscribe')
  static async subscribe(request: SaveSubscribeRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @queryPost<QueryUserSubscribeResponse>('/vscode/merge/subscribe/query')
  static async querySubscribe(request: QueryMergeItemInfoRequest): Promise<PageResponse<QueryUserSubscribeResponse>> {
    throw new EmptyHttpError()
  }

  @queryPost<GetUserSubscribeResponse>('/vscode/merge/subscribe/get')
  static async getSubscribe(request: { item_type: number }): Promise<PageResponse<GetUserSubscribeResponse>> {
    throw new EmptyHttpError()
  }
}

export const MergeViewObj: ApiWinModel<QueryMergeRequest, QueryMergeResponse> =
  new ApiWinModel(() => new QueryMergeRequest(), () => new QueryMergeResponse())
export const editModel = () => new GetMergeResponse()
export const getModelTitle = (r: GetMergeResponse | QueryMergeResponse) => r.name
export const MergeOper: ISaveEditRequest<GetMergeResponse, SaveMergeRequest, EditMergeRequest> = new ISaveEditRequest(
  {
    entity: () => new GetMergeResponse(),
    save: () => new SaveMergeRequest(),
    edit: () => new EditMergeRequest()
  },
  {
    entity: MergeApi.getById,
    save: MergeApi.save,
    edit: MergeApi.edit,
    del: MergeApi.delete
  }
)
