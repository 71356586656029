/* eslint-disable camelcase */
import { queryText, queryContent, querySlot } from '@/util/models/admin/queryItem'
import { tableDate, tableEditUrl, tableIdRow, tableText, tableIcon, tableEnable, tableButton } from '@/util/models/admin/tableItem'
import { fromSlot, fromInput, areaInput, fromSwitch } from '@/util/models/admin/fromItem'
import { PageResponse, PageRequest, IdNameRemarkExEntity } from '@util/models/ServerBaseEntity'
import type { IdEntity } from '@util/models/ServerIdEntity'
import { adaptPostValid, ApiWinModel, queryPost, EmptyHttpError, execPostValid, ISaveEditRequest, adaptHidePostValid } from '@util/utils/HttpClient'
import { vr, IValid, ValidIdRequest } from '@/util/utils/validator'
import { getIconClass } from '@/util/utils/iconUtils'
import { dateFormat } from '@/util/utils/stringUtils'

export class SaveMethodRequest extends IValid {
  @vr({ required: true, min: 0, message: '引擎类型不能为空', type: 'number' })
  type?: number

  @vr({ required: true, max: 128, message: '函数关键字不能超过128个字符' })
  prefix: string = ''

  @vr({ required: true, max: 128, message: '函数名称不能超过128个字符' })
  method: string = ''

  @vr({ required: true, min: 0, message: '函数类型不能为空', type: 'number' })
  mode: number = 0

  @vr({ max: 1024, message: '函数说明不能超过1024个字符' })
  description: string = ''

  @vr({ max: 1024, message: '函数详情不能超过1024个字符' })
  details: string = ''

  @vr({ max: 1024, message: '函数主体不能超过1024个字符' })
  body: string = ''

  @vr({ max: 1024, message: '函数例子不能超过1024个字符' })
  example: string = ''

  @vr({ min: 0, max: 1024, message: '函数参数不能超过2048个字符' })
  params: string = ''

  not_hover: boolean = false
  not_snippet: boolean = false
  waring: boolean = false

  @vr({ required: true, min: 0, message: '公开类型不能为空', type: 'number' })
  auth_type: number = 0
}
export class EditMethodRequest extends IValid implements IdEntity {
  @vr({ required: true, min: 0, message: 'ID不能为空', type: 'number' })
  id: number = 0

  @vr({ required: true, min: 0, message: '引擎类型不能为空', type: 'number' })
  type?: number

  @vr({ required: true, max: 128, message: '函数关键字不能超过128个字符' })
  prefix: string = ''

  @vr({ required: true, max: 128, message: '函数名称不能超过128个字符' })
  method: string = ''

  @vr({ required: true, min: 0, message: '函数类型不能为空', type: 'number' })
  mode: number = 0

  @vr({ max: 1024, message: '函数说明不能超过1024个字符' })
  description: string = ''

  @vr({ max: 1024, message: '函数详情不能超过1024个字符' })
  details: string = ''

  @vr({ max: 1024, message: '函数主体不能超过1024个字符' })
  body: string = ''

  @vr({ max: 1024, message: '函数例子不能超过1024个字符' })
  example: string = ''

  @vr({ max: 2048, message: '函数参数不能超过2048个字符' })
  params: string = ''

  not_hover: boolean = false
  not_snippet: boolean = false
  waring: boolean = false

  @vr({ required: true, min: 0, message: '公开类型不能为空', type: 'number' })
  auth_type: number = 0
}
export class QueryMethodRequest extends PageRequest {
  @queryContent({ loadQuery: true })
  @queryText({ text: '函数名称' })
  name: string = ''

  @querySlot({ title: '引擎类型' })
  type: number | undefined

  @querySlot({ title: '公开类型' })
  auth_type: number | undefined
}
export class QueryMethodResponse implements IdEntity {
  @tableButton({
    text: '导入',
    val: { position: 'header', width: 130 },
    style: { icon: getIconClass('el-icon-import'), type: undefined, size: 'default' },
    e: { click: 'import' }
  })
  @tableIdRow()
  id: number = 0

  @tableEditUrl({ header: '函数名称', e: { click: 'edit' } })
  method: string = ''

  @tableEditUrl({ header: '函数关键字', e: { click: 'edit' } })
  prefix: string = ''

  type: number = 0
  @tableText({ header: '引擎类型', width: 90 })
  type_name: string = ''

  auth_type: number | undefined
  @tableText({ header: '公开类型', width: 90 })
  auth_name: string | undefined

  mode: number | undefined
  @tableText({ header: '函数类型', width: 90 })
  mode_name: string | undefined

  @tableEnable({ header: '移入显示', text: { true: '是', false: '' } })
  not_hover: boolean | undefined

  @tableEnable({ header: '自动补全', text: { true: '是', false: '' } })
  not_snippet: boolean | undefined

  @tableEnable({ header: '执行警告', text: { true: '是', false: '' } })
  waring: boolean | undefined

  @tableDate({ header: '修改时间' })
  modify_time: string = ''
}

export interface IMethodPararItem {
  required: boolean
  name: string
  value: string | null | string[]
  default: number | undefined
}
export function MethodAdapt(index: number, req: { key: string, val: any }, type: number, auth_type: number, type_name: string, auth_type_name: string, getMode: (id: number) => string) {
  const resp: any = new GetMethodResponse()
  resp.id = index
  resp.prefix = req.val.prefix ?? req.val.method
  resp.type = type
  resp.auth_type = auth_type
  resp.type_name = type_name
  resp.auth_name = auth_type_name
  resp.mode = req.val.mode ?? 1
  if (resp.mode === 'act') {
    resp.mode = 1
  }
  if (resp.mode === 'if') {
    resp.mode = 0
  }
  if (resp.mode instanceof String) {
    resp.mode = 1
  }
  resp.mode_name = getMode(resp.mode)
  resp.params = req.val.params ?? ''
  if (resp.params instanceof Object) {
    const tmps: IMethodPararItem[] = []
    for (const key in resp.params) {
      const tmpVal = resp.params[key]
      let targetVal = tmpVal
      if (tmpVal instanceof Object) {
        targetVal = []
        for (const vk in tmpVal) {
          if (vk === 'NaN') {
            continue
          }
          const vv: any = tmpVal[vk]
          targetVal.push(vv)
        }
      }
      tmps.push({ name: key, required: false, default: 0, value: targetVal })
    }
    resp.params = JSON.stringify(tmps)
  } else if (resp.params instanceof Array) {
    resp.params = JSON.stringify(resp.params)
  } else if (resp.params instanceof String) {
    if (resp.params === '{}') {
      resp.params = JSON.stringify([])
    }
  }
  resp.not_hover = !(req.val.not_hover ?? false)
  resp.not_snippet = !(req.val.not_snippet ?? false)
  resp.waring = req.val.waring ?? (req.val.warng ?? false)
  resp.example = req.val.example ?? ''
  resp.body = req.val.body ?? ''
  if (resp.body instanceof Array) {
    if (resp.body.length === 1) {
      resp.body = resp.body[0]
    } else {
      let str = ''
      for (let i = 0; i < resp.body.length; i++) {
        if (i !== 0) {
          str += '\n'
        }
        str += resp.body[i]
      }
      resp.body = str
      // console.log(req.key, resp.body.length)
    }
  }
  resp.details = req.val.details ?? ''
  resp.description = req.val.description ?? ''
  resp.create_time = dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss')
  resp.modify_time = dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss')
  return resp
}
export class GetMethodResponse implements IdEntity {
  id: number = 0

  @fromInput({ title: '函数名称' })
  method: string = ''

  @fromInput({ title: '函数关键字' })
  prefix: string = ''

  @fromSlot({ title: '引擎类型' })
  type?: number

  @fromSlot({ title: '公开类型' })
  auth_type: number | undefined = 0

  @fromSlot({ title: '函数类型' })
  mode: number = 0

  params: string = ''

  not_hover: boolean = false

  not_snippet: boolean = false

  waring: boolean = false

  example: string = ''

  body: string = ''

  details: string = ''

  description: string = ''
}

export class MethodApi {
  @execPostValid('/vscode/method/save')
  static async save(request: SaveMethodRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/vscode/method/edit')
  static async edit(request: EditMethodRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/vscode/method/delete')
  static async delete(request: ValidIdRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/vscode/method/import')
  static async import(request: SaveMethodRequest[]): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @queryPost<QueryMethodResponse>('/vscode/method/query')
  static async query(request: QueryMethodRequest): Promise<PageResponse<QueryMethodResponse>> {
    throw new EmptyHttpError()
  }

  @queryPost<IdNameRemarkExEntity>('/vscode/method/queryIdName')
  static async queryIdName(request: PageRequest): Promise<PageResponse<IdNameRemarkExEntity>> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetMethodResponse>('/vscode/method/getById', (resp) => resp ?? { id: 0 })
  static async getById(request: ValidIdRequest): Promise<GetMethodResponse> {
    throw new EmptyHttpError()
  }
}

export const MethodViewObj: ApiWinModel<QueryMethodRequest, QueryMethodResponse> =
  new ApiWinModel(() => new QueryMethodRequest(), () => new QueryMethodResponse())
export const editModel = () => new GetMethodResponse()
export const getModelTitle = (r: GetMethodResponse | QueryMethodResponse) => r.prefix
export const MethodOper: ISaveEditRequest<GetMethodResponse, SaveMethodRequest, EditMethodRequest> = new ISaveEditRequest(
  {
    entity: () => new GetMethodResponse(),
    save: () => new SaveMethodRequest(),
    edit: () => new EditMethodRequest()
  },
  {
    entity: MethodApi.getById,
    save: MethodApi.save,
    edit: MethodApi.edit,
    del: MethodApi.delete
  }
)
