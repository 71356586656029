import { IdNameEntity } from '@util/models/ServerBaseEntity'

export const AuthTypeList: Array<IdNameEntity> = [{ id: 0, name: '公开' }, { id: 1, name: '私有' }]
export const MethdoModelList: Array<IdNameEntity> = [{ id: 0, name: '检测函数' }, { id: 1, name: '执行函数' }]
export const FileFormatList: Array<IdNameEntity> = [
  { id: 0, name: '无格式化' },
  { id: 1, name: '脚本格式化' },
  { id: 2, name: '怪物爆率格式化' },
  { id: 3, name: '地图格式化' }
]

export const SettingTypeList: Array<IdNameEntity> = [
  { id: 0, name: '系统文件' },
  { id: 1, name: '系统事件' },
  { id: 2, name: '系统常量' },
  { id: 3, name: '地图变量' },
  { id: 4, name: '自定义函数' }
]
export const menuExecType: IdNameEntity[] = [
  { id: 0, name: '调用Vs命令' },
  { id: 1, name: '执行Batch命令' },
  { id: 1000, name: '调用应用菜单' }
]
export const SettingTypeMap: { [index: number]: string } = {}
export const AuthTypeMap: { [index: number]: string } = {}
export const menuExecMap: { [index: number]: string } = {}
SettingTypeList.forEach(s => { SettingTypeMap[s.id] = s.name })
AuthTypeList.forEach(s => { AuthTypeMap[s.id] = s.name })
menuExecType.forEach(s => { menuExecMap[s.id] = s.name })
