/* eslint-disable camelcase */
import { adaptPostValid, ApiWinModel, queryPost, EmptyHttpError, execPostValid, ISaveEditRequest, formData } from '@util/utils/HttpClient'
import { vr, IValid, ValidIdRequest } from '@/util/utils/validator'
import { IdEntity } from '@/util/models/ServerIdEntity'
import { PageRequest, PageResponse, UploadFileResponse } from '@/util/models/ServerBaseEntity'
import { queryContent, queryText } from '@/util/models/admin/queryItem'
import { tableDate, tableEditUrl, tableIdRow, tableText } from '@/util/models/admin/tableItem'
import { fromInput, fromSlot, areaInput } from '@/util/models/admin/fromItem'

export class SaveCosRequest extends IValid {
  @vr({ required: true, max: 200, message: '文件名称不能超过200个字符' })
  name: string = ''

  @vr({ required: false, max: 1024, message: '文件备注不能超过1024个字符' })
  remark: string = ''

  @vr({ required: true, max: 100, message: '文件URL不能超过100个字符' })
  url: string = ''

  @vr({ required: true, max: 100, message: '文件名称不能超过100个字符' })
  filename: string = ''

  @vr({ required: false, max: 50, message: '文件校验不能超过50个字符' })
  md5: string = ''
}

export class EditCosRequest extends IValid implements IdEntity {
  @vr({ required: true, min: 0, message: 'ID不能为空', type: 'number' })
  id: number = 0

  @vr({ required: true, max: 200, message: '文件名称不能超过200个字符' })
  name: string = ''

  @vr({ required: false, max: 1024, message: '文件备注不能超过1024个字符' })
  remark: string = ''

  @vr({ required: true, max: 100, message: '文件URL不能超过100个字符' })
  url: string = ''

  @vr({ required: true, max: 100, message: '文件名称不能超过100个字符' })
  filename: string = ''

  @vr({ required: false, max: 50, message: '文件校验不能超过50个字符' })
  md5: string = ''
}

export class QueryCosRequest extends PageRequest {
  @queryContent({ loadQuery: true })
  @queryText({ text: '文件名称' })
  name: string = ''
}

export class QueryCosResponse implements IdEntity {
  @tableIdRow()
  id: number = 0

  @tableEditUrl({ header: '文件别名', e: { click: 'edit' } })
  name: string = ''

  @tableText({ header: '文件名称' })
  filename: string = ''

  @tableText({ header: '文件校验' })
  md5: string = ''

  @tableDate({ header: '创建时间' })
  create_time: string = ''

  @tableDate({ header: '修改时间' })
  modify_time: string = ''
}

export class GetCosResponse implements IdEntity {
  id: number = 0

  @fromInput({ title: '文件别名' })
  name: string = ''

  url: string = ''

  filename: string = ''

  md5: string = ''

  remark: string = ''
}

export class CosApi {
  static async upload(request: File): Promise<UploadFileResponse> {
    const resp = await formData<UploadFileResponse>('/vscode/cos/temp/upload', request)
    return resp ?? {
      url: '',
      filename: '',
      success: false
    }
  }

  @execPostValid('/vscode/cos/save')
  static async save(request: SaveCosRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/vscode/cos/edit')
  static async edit(request: EditCosRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/vscode/cos/delete')
  static async delete(request: ValidIdRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @queryPost<QueryCosResponse>('/vscode/cos/query')
  static async query(request: QueryCosRequest): Promise<PageResponse<QueryCosResponse>> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetCosResponse>('/vscode/cos/getById', (resp) => resp ?? { id: 0 })
  static async getById(request: ValidIdRequest): Promise<GetCosResponse> {
    throw new EmptyHttpError()
  }
}

export const CosViewObj: ApiWinModel<QueryCosRequest, QueryCosResponse> =
  new ApiWinModel(() => new QueryCosRequest(), () => new QueryCosResponse())
export const editModel = () => new GetCosResponse()
export const getModelTitle = (r: GetCosResponse | QueryCosResponse) => r.name
export const CosOper: ISaveEditRequest<GetCosResponse, SaveCosRequest, EditCosRequest> = new ISaveEditRequest(
  {
    entity: () => new GetCosResponse(),
    save: () => new SaveCosRequest(),
    edit: () => new EditCosRequest()
  },
  {
    entity: CosApi.getById,
    save: CosApi.save,
    edit: CosApi.edit,
    del: CosApi.delete
  }
)
