/* eslint-disable camelcase */
import { queryText, queryContent, querySlot } from '@/util/models/admin/queryItem'
import { tableDate, tableEditUrl, tableIdRow, tableText, tableSlot, tableIcon, tableEnable } from '@/util/models/admin/tableItem'
import { fromSlot, fromInput, areaInput, fromSwitch } from '@/util/models/admin/fromItem'
import { PageResponse, PageRequest, IdNameEntity } from '@util/models/ServerBaseEntity'
import type { IdEntity } from '@util/models/ServerIdEntity'
import { adaptPostValid, ApiWinModel, queryPost, EmptyHttpError, execPostValid, ISaveEditRequest } from '@util/utils/HttpClient'
import { vr, IValid, ValidIdRequest } from '@/util/utils/validator'
import { Check, Close } from '@element-plus/icons-vue'

export class SaveSystemMenuRequest extends IValid {
  @vr({ required: true, min: 2, message: '菜单名称不能为空' })
  name: string = ''

  default_flag: boolean = false

  @vr({ max: 1024, message: '菜单说明不能超过1000个字符' })
  remark: string = ''

  @vr({ max: 200, message: '菜单命令不能超过200字符' })
  command: string = ''

  @vr({ required: true, type: 'number', min: 0, message: '执行类型不能为空' })
  execute_type: number = 0
}
export class EditSystemMenuRequest extends IValid implements IdEntity {
  @vr({ required: true, min: 0, message: '菜单ID不能为空', type: 'number' })
  id: number = 0

  @vr({ required: true, min: 2, message: '菜单名称不能为空' })
  name: string = ''

  default_flag: boolean = false

  @vr({ max: 1024, message: '菜单说明不能超过1000个字符' })
  remark: string = ''

  @vr({ max: 200, message: '菜单命令不能超过200字符' })
  command: string = ''

  @vr({ required: true, type: 'number', min: 0, message: '执行类型不能为空' })
  execute_type: number = 0
}
export class QuerySystemMenuRequest extends PageRequest {
  @queryContent({ loadQuery: true })
  @queryText({ text: '菜单名称' })
  name: string = ''
}
export class QuerySystemMenuResponse implements IdEntity {
  @tableIdRow()
  id: number = 0

  @tableEditUrl({ header: '菜单名称', e: { click: 'edit' } })
  name: string = ''

  @tableEnable({ header: '默认菜单', text: { true: '是', false: '否' } })
  default_flag: boolean = false

  @tableSlot({ header: '执行类型' })
  execute_type: number = 0

  @tableDate({ header: '创建时间' })
  create_time: string = ''

  @tableDate({ header: '修改时间' })
  modify_time: string = ''
}
export class GetSystemMenuResponse implements IdEntity {
  id: number = 0

  @fromInput({ title: '菜单名称' })
  name: string = ''

  @fromSwitch({ title: '默认菜单' }, { icon: Check }, { icon: Close })
  default_flag: boolean = false

  execute_type: number = 0

  command: string = ''

  remark: string = ''
}
export interface QueryPluginMenuItemResponse extends IdEntity {
  name: string
  remark: string
  command: string
  execute_type: number
  children: Array<QueryPluginMenuItemResponse>
}
export interface QueryUserMenuRequest {
}
export interface SavePluginMenuItemInfoRequest {
  id: number
  name: string
  remark: string
  command: string
  execute_type: number | undefined
}
export interface SavePluginMenuItemRequest extends SavePluginMenuItemInfoRequest {
  children: Array<SavePluginMenuItemRequest>
}
export class SavePluginMenuItemInfo implements SavePluginMenuItemInfoRequest {
  id: number = 0
  execute_type: number | undefined

  @fromSlot({ title: '菜单名称' })
  name: string = ''

  @areaInput({ title: '执行命令', min: 2, maxlength: 200 })
  command: string = ''

  @areaInput({ title: '命令说明', min: 4, maxlength: 1024 })
  remark: string = ''

  array: Array<SavePluginMenuItemRequest> = []
  isInsert: boolean = true
  old: any | undefined
}

export class MenuApi {
  @execPostValid('/svscode/menus/save')
  static async save(request: SaveSystemMenuRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/svscode/menus/edit')
  static async edit(request: EditSystemMenuRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/svscode/menus/delete')
  static async delete(request: ValidIdRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @queryPost<QuerySystemMenuResponse>('/vscode/menus/query')
  static async query(request: QuerySystemMenuRequest): Promise<PageResponse<QuerySystemMenuResponse>> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetSystemMenuResponse>('/vscode/menus/getById', (resp) => resp ?? { id: 0 })
  static async getById(request: ValidIdRequest): Promise<GetSystemMenuResponse> {
    throw new EmptyHttpError()
  }

  @queryPost<SavePluginMenuItemInfoRequest>('/vscode/menus/queryBySimple')
  static async queryBySimple(request: QuerySystemMenuRequest): Promise<PageResponse<SavePluginMenuItemInfoRequest>> {
    throw new EmptyHttpError()
  }
}

export const MenuViewObj: ApiWinModel<QuerySystemMenuRequest, QuerySystemMenuResponse> =
  new ApiWinModel(() => new QuerySystemMenuRequest(), () => new QuerySystemMenuResponse())
export const editModel = () => new GetSystemMenuResponse()
export const getModelTitle = (r: GetSystemMenuResponse | QuerySystemMenuResponse) => r.name
export const MenuOper: ISaveEditRequest<GetSystemMenuResponse, SaveSystemMenuRequest, EditSystemMenuRequest> = new ISaveEditRequest(
  {
    entity: () => new GetSystemMenuResponse(),
    save: () => new SaveSystemMenuRequest(),
    edit: () => new EditSystemMenuRequest()
  },
  {
    entity: MenuApi.getById,
    save: MenuApi.save,
    edit: MenuApi.edit,
    del: MenuApi.delete
  }
)
