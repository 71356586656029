
import LoginRedirect from '@/views/LoginRedirect.vue'
import systemMenu from '@/views/systemMenu.vue'
import systemTarget from '@/views/systemTarget.vue'
import menu from '@/views/userMenu.vue'
import console from '@/views/userConsole.vue'
import file from '@/views/setting/MirFile.vue'
import constant from '@/views/setting/MirConstant.vue'
import event from '@/views/setting/MirEvent.vue'
import map from '@/views/setting/MirMapParam.vue'
import method from '@/views/setting/MirMethod.vue'
import merge from '@/views/userMerge.vue'
import MergeEdit from '@/views/MergeEdit.vue'
import methodImport from '@/views/setting/methodImport.vue'
import vscodeConfig from '@/views/vscodeConfig.vue'
import plugConfig from '@/views/plugConfig.vue'
import question from '@/views/systemQuestion.vue'
import MirFileView from '@/views/MirFileView.vue'
import attr996Generate from '@/views/tools/996AttrGenerate.vue'
import ToolsParent from '@/views/tools/ToolsParent.vue'

export default [
  {
    path: '/',
    component: LoginRedirect,
    name: '登录等待',
    meta: {
      hasRoot: true
    },
    children: []
  }, {
    path: '/index',
    component: console,
    name: '控制台',
    meta: {
      hasRoot: true,
      icon: 'el-icon-kongzhitaishouye',
      title: '用户插件',
      desc: ''
    },
    children: []
  }, {
    path: '/question',
    component: question,
    name: '插件疑问索引',
    meta: {
      hasRoot: true,
      icon: 'el-icon-kongxinwenhao',
      title: '疑问索引',
      desc: ''
    },
    children: []
  }, {
    path: '/config',
    component: vscodeConfig,
    name: '插件配置',
    meta: {
      hasRoot: true,
      icon: 'el-icon-setting',
      title: '插件配置',
      desc: ''
    },
    children: []
  }, {
    path: '/plug/config',
    component: plugConfig,
    name: '插件配置信息',
    meta: {
      hasRoot: true,
      icon: 'el-icon-setting',
      title: '插件配置',
      desc: ''
    },
    children: []
  }, {
    path: '/menu',
    component: menu,
    name: '插件快捷菜单',
    meta: {
      icon: 'el-icon-menu',
      title: '快捷菜单',
      desc: ''
    },
    children: []
  }, {
    path: '/systemMenu',
    component: systemMenu,
    name: '插件菜单',
    meta: {
      icon: 'el-icon-menu',
      title: '插件菜单',
      desc: ''
    },
    children: []
  }, {
    path: '/target',
    component: systemTarget,
    name: '插件引擎类型',
    meta: {
      icon: 'el-icon-liebiao',
      title: '引擎类型',
      desc: ''
    },
    children: []
  },
  {
    path: '/fileConfig',
    component: ToolsParent,
    name: '插件文件设置',
    meta: {
      icon: 'el-icon-file-open',
      title: '插件文件设置',
      desc: ''
    },
    children: [{
      path: '/file',
      component: file,
      name: '插件系统文件',
      meta: {
        icon: 'el-icon-image-text',
        title: '系统文件',
        desc: ''
      },
      children: []
    }, {
      path: '/event',
      component: event,
      name: '插件系统事件',
      meta: {
        icon: 'el-icon-electronics',
        title: '系统事件',
        desc: ''
      },
      children: []
    }, {
      path: '/constant',
      component: constant,
      name: '插件系统常量',
      meta: {
        icon: 'el-icon-xitongmoxingchajian',
        title: '系统常量',
        desc: ''
      },
      children: []
    }, {
      path: '/mapParam',
      component: map,
      name: '插件地图变量',
      meta: {
        icon: 'el-icon-filedone',
        title: '地图变量',
        desc: ''
      },
      children: []
    }]
  }, {
    path: '/method',
    component: method,
    name: '插件函数设定',
    meta: {
      icon: 'el-icon-a-icon_Plug-incode',
      title: '函数设定',
      desc: ''
    },
    children: []
  }, {
    path: '/method/import',
    component: methodImport,
    name: '插件函数导入',
    meta: {
      icon: 'el-icon-a-icon_Plug-incode',
      title: '函数导入',
      desc: ''
    },
    children: []
  }, {
    path: '/merge',
    component: merge,
    name: '插件配置合并',
    meta: {
      icon: 'el-icon-24gl-merge',
      title: '配置订阅',
      desc: ''
    },
    children: []
  }, {
    path: '/merge/edit',
    component: MergeEdit,
    name: '插件配置合并编辑',
    meta: {
      icon: 'el-icon-24gl-merge',
      title: '配置合并编辑',
      activeMenu: '/merge',
      desc: '',
      show: false
    },
    children: []
  }, {
    path: '/tools',
    name: '传奇工具',
    component: ToolsParent,
    meta: {
      icon: 'el-icon-xitongmoxingchajian',
      title: '传奇工具',
      desc: ''
    },
    children: [
      {
        path: '/clientFile',
        component: MirFileView,
        name: '插件文件系统',
        meta: {
          icon: 'el-icon-download',
          title: '文件系统',
          desc: ''
        },
        children: []
      },
      {
        path: '/tools/996/attr',
        component: attr996Generate,
        name: '996属性生成',
        meta: {
          icon: 'el-icon-xitongmoxingchajian',
          title: '996属性生成',
          desc: ''
        },
        children: [
        ]
      }
    ]
  }
]
