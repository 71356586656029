/* eslint-disable camelcase */
import { vr, IValid, ValidIdRequest } from '@util/utils/validator'
import { adaptPassword } from '@util/utils/stringUtils'
import { checkPageResponse, ExecuteResponse, IdNameEntity, PageResponse } from '@util/models/ServerBaseEntity'
import type { IdEntity } from '@util/models/ServerIdEntity'
import { setTokenInfo as saveToken, clearToken, getRefreshToken, hasLogin } from '@/store'
import { getPost, post, adaptPost, adaptPostValid, ApiWinModel, queryPost, EmptyHttpError, execPostValid, execHidePostValid } from '@util/utils/HttpClient'

export interface UserLoginByPassword {
  password: string
  username: string
}
export interface BackImageResponse {
  image: string | undefined
}
export class UserLoginInfo extends IValid implements UserLoginByPassword {
  @vr([{
    required: true,
    message: '登录密码不能为空'
  }, {
    min: 5,
    message: '登录密码最少输入5位'
  }])
  password: string

  @vr([{
    required: true,
    message: '登录账号不能为空'
  }, {
    min: 5,
    max: 50,
    message: '登录账号最少输入5位'
  }])
  username: string

  remember: boolean

  constructor() {
    super()
    this.username = ''
    this.password = ''
    this.remember = false
  }
}
interface ServerAppResponse {
  app_name: string
  app_icon: string
  app_key: number
  app_desc: string
  app_url: string
}
export interface GetUserAppMenuResponse extends IdEntity {
  menu_name: string
  menu_icon: string
  menu_title: string
  menu_desc: string
}
export interface GetAuthAppMapResponse extends IdEntity {
  app_name: string
  app_url: string
  app_icon: string
  group_id: number
  groups: Array<IdNameEntity>
  menus: Array<GetUserAppMenuResponse>
}

interface LoginResponse {
  access_token: string
  refresh_token: string
  username: string
  expired: number
}
interface ChangeGroupIdRequest {
  group_id: number
}

interface RefreshTokenRequest {
  refresh_token: string
}

function setTokenInfo(resp: LoginResponse, remember: boolean | undefined) {
  saveToken({
    access_token: resp.access_token,
    refresh_token: resp.refresh_token,
    user_name: resp.username,
    expired: resp.expired,
    remember
  })
}
async function clearTokenInfo() {
  await clearToken()
}

export class TokenApi {
  @execHidePostValid('/acc/license/gen')
  static async addLicense(request: { license_code: string }): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @getPost<BackImageResponse, string>('/auth/getBackImage', (resp) => resp?.image ?? '')
  static async getBingImage(): Promise<string> {
    throw new EmptyHttpError()
  }

  @getPost<PageResponse<ServerAppResponse>, Array<ServerAppResponse>>('/auth/getApps', (resp) => checkPageResponse(resp).list)
  static async getApps(): Promise<Array<ServerAppResponse>> {
    throw new EmptyHttpError()
  }

  @getPost<GetAuthAppMapResponse, any>('/auth/getAppMap', (resp) => resp ?? {})
  static async getAppMap(request: IdEntity): Promise<GetAuthAppMapResponse> {
    throw new EmptyHttpError()
  }

  @post('/auth/login/refresh')
  private static async invokeRefresh(req: RefreshTokenRequest): Promise<LoginResponse> {
    throw new EmptyHttpError()
  }

  static async refresh() {
    const refreshToken = getRefreshToken()
    if (refreshToken === undefined || refreshToken == null) {
      throw new Error('refresh token not found')
    }
    const resp = await TokenApi.invokeRefresh({ refresh_token: refreshToken })
    setTokenInfo(resp, undefined)
    return true
  }

  @post('/auth/login/pass')
  private static async invokeLogin(req: UserLoginByPassword): Promise<LoginResponse> {
    throw new EmptyHttpError()
  }

  @getPost<ExecuteResponse, boolean>('/auth/group', (resp) => resp?.success ?? false)
  public static async changeGroup(req: ChangeGroupIdRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @getPost<ExecuteResponse, boolean>('/auth/logout', (resp) => resp?.success ?? false)
  private static async invokeLogout(): Promise<boolean> {
    throw new EmptyHttpError()
  }

  static async login(userInfo: UserLoginInfo) {
    if (userInfo === undefined) {
      throw new Error('user info not found')
    }
    const resp = await TokenApi.invokeLogin({
      username: userInfo.username,
      password: adaptPassword(userInfo.password)
    })
    setTokenInfo(resp, userInfo.remember)
  }

  static async logout(isServer: boolean = true) {
    if (isServer) {
      let resp: boolean | undefined
      if (hasLogin()) {
        resp = await TokenApi.invokeLogout()
      }
      clearTokenInfo()
      if (resp !== undefined && resp) {
        return true
      } else {
        return false
      }
    } else {
      clearTokenInfo()
      return true
    }
  }
}
