
import { adaptHidePostValid, EmptyHttpError, execHidePostValid } from '@util/utils/HttpClient'
import { IValid, ValidIdRequest, vr } from '@/util/utils/validator'

export interface GetVscodeUserConfigResponse {
  content: string
}
export class SaveVscodeUserConfigRequest extends IValid {
  @vr({ required: true, min: 2, message: '菜单名称不能为空' })
  content: string = ''
}

export class VSConfigApi {
  @execHidePostValid('/vscode/config/save')
  static async save(request: SaveVscodeUserConfigRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @adaptHidePostValid<GetVscodeUserConfigResponse>('/vscode/config/getById', (resp) => resp ?? { content: undefined })
  static async getById(request: ValidIdRequest): Promise<GetVscodeUserConfigResponse> {
    throw new EmptyHttpError()
  }
}
