/* eslint-disable camelcase */
import { queryText, queryContent, querySlot } from '@/util/models/admin/queryItem'
import { tableDate, tableEditUrl, tableIdRow, tableText, tableIcon, tableEnable, tableButton } from '@/util/models/admin/tableItem'
import { fromSlot, fromInput, areaInput, fromSwitch } from '@/util/models/admin/fromItem'
import { PageResponse, PageRequest, IdNameRemarkExEntity } from '@util/models/ServerBaseEntity'
import type { IdEntity } from '@util/models/ServerIdEntity'
import { adaptPostValid, ApiWinModel, queryPost, EmptyHttpError, execPostValid, ISaveEditRequest } from '@util/utils/HttpClient'
import { vr, IValid, ValidIdRequest } from '@/util/utils/validator'
import { getIconClass } from '@/util/utils/iconUtils'
import { dateFormat } from '@/util/utils/stringUtils'

export class SaveConstantRequest extends IValid {
  @vr({ required: true, min: 0, message: '引擎类型不能为空', type: 'number' })
  type: number = 0

  @vr({ required: true, max: 200, message: '常量关键字不能超过200个字符' })
  constant_field: string = ''

  @vr({ required: true, max: 200, message: '常量名称不能超过200个字符' })
  name: string = ''

  @vr({ max: 1024, message: '常量说明不能超过1000个字符' })
  remark: string = ''

  @vr({ required: true, min: 0, message: '公开类型不能为空', type: 'number' })
  auth_type: number = 0
}
export class EditConstantRequest extends IValid implements IdEntity {
  @vr({ required: true, min: 0, message: 'ID不能为空', type: 'number' })
  id: number = 0

  @vr({ required: true, min: 0, message: '引擎类型不能为空', type: 'number' })
  type: number = 0

  @vr({ required: true, max: 200, message: '常量关键字不能超过200个字符' })
  constant_field: string = ''

  @vr({ required: true, max: 200, message: '常量名称不能超过200个字符' })
  name: string = ''

  @vr({ max: 1024, message: '常量说明不能超过1000个字符' })
  remark: string = ''

  @vr({ required: true, min: 0, message: '公开类型不能为空', type: 'number' })
  auth_type: number = 0
}
export class QueryConstantRequest extends PageRequest {
  @queryContent({ loadQuery: true })
  @queryText({ text: '常量名称' })
  name: string = ''

  @querySlot({ title: '引擎类型' })
  type: number | undefined

  @querySlot({ title: '公开类型' })
  auth_type: number | undefined
}
export class QueryConstantResponse implements IdEntity {
  @tableButton({
    text: '导入',
    val: { position: 'header', width: 130 },
    style: { icon: getIconClass('el-icon-import'), type: undefined, size: 'default' },
    e: { click: 'import' }
  })
  @tableIdRow()
  id: number = 0

  @tableEditUrl({ header: '常量名称', e: { click: 'edit' } })
  name: string = ''

  @tableEditUrl({ header: '常量关键字', e: { click: 'edit' } })
  constant_field: string = ''

  type: number = 0
  @tableText({ header: '引擎类型', width: 90 })
  type_name: string = ''

  auth_type: number | undefined
  @tableText({ header: '公开类型', width: 90 })
  auth_name: string | undefined

  @tableDate({ header: '创建时间' })
  create_time: string = ''

  @tableDate({ header: '修改时间' })
  modify_time: string = ''
}
export class GetConstantResponse implements IdEntity {
  id: number = 0

  @fromInput({ title: '常量名称' })
  name: string = ''

  @fromSlot({ title: '引擎类型' })
  type: string = ''

  @fromInput({ title: '常量关键字' })
  constant_field: string = ''

  @fromSlot({ title: '公开类型' })
  auth_type: number | undefined = 0

  @areaInput({ title: '常量说明', min: 3, maxlength: 1024 })
  remark: string = ''
}

export class ConstantApi {
  @execPostValid('/vscode/constant/save')
  static async save(request: SaveConstantRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/vscode/constant/edit')
  static async edit(request: EditConstantRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/vscode/constant/delete')
  static async delete(request: ValidIdRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/vscode/constant/import')
  static async import(request: SaveConstantRequest[]): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @queryPost<QueryConstantResponse>('/vscode/constant/query')
  static async query(request: QueryConstantRequest): Promise<PageResponse<QueryConstantResponse>> {
    throw new EmptyHttpError()
  }

  @queryPost<IdNameRemarkExEntity>('/vscode/constant/queryIdName')
  static async queryIdName(request: PageRequest): Promise<PageResponse<IdNameRemarkExEntity>> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetConstantResponse>('/vscode/constant/getById', (resp) => resp ?? { id: 0 })
  static async getById(request: ValidIdRequest): Promise<GetConstantResponse> {
    throw new EmptyHttpError()
  }
}

export function ConstantAdapt(index: number, req: { key: string, val: any }, type: number, auth_type: number, type_name: string, auth_type_name: string) {
  const tmp: any = new GetConstantResponse()
  tmp.id = index
  tmp.name = req.val.name
  tmp.constant_field = req.key
  tmp.remark = req.val?.desc ?? req.val?.remark ?? ''
  tmp.type = type
  tmp.auth_type = auth_type
  tmp.type_name = type_name
  tmp.auth_name = auth_type_name
  tmp.create_time = dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss')
  tmp.modify_time = dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss')
  return tmp
}
export const ConstantViewObj: ApiWinModel<QueryConstantRequest, QueryConstantResponse> =
  new ApiWinModel(() => new QueryConstantRequest(), () => new QueryConstantResponse())
export const editModel = () => new GetConstantResponse()
export const getModelTitle = (r: GetConstantResponse | QueryConstantResponse) => r.name
export const ConstantOper: ISaveEditRequest<GetConstantResponse, SaveConstantRequest, EditConstantRequest> = new ISaveEditRequest(
  {
    entity: () => new GetConstantResponse(),
    save: () => new SaveConstantRequest(),
    edit: () => new EditConstantRequest()
  },
  {
    entity: ConstantApi.getById,
    save: ConstantApi.save,
    edit: ConstantApi.edit,
    del: ConstantApi.delete
  }
)
