/* eslint-disable camelcase */
import { queryText, queryContent, querySlot } from '@/util/models/admin/queryItem'
import { tableDate, tableEditUrl, tableIdRow, tableText, tableIcon, tableEnable } from '@/util/models/admin/tableItem'
import { fromSlot, fromInput, areaInput, fromSwitch } from '@/util/models/admin/fromItem'
import { PageResponse, PageRequest } from '@util/models/ServerBaseEntity'
import type { IdEntity } from '@util/models/ServerIdEntity'
import { adaptPostValid, ApiWinModel, queryPost, EmptyHttpError, execPostValid, ISaveEditRequest } from '@util/utils/HttpClient'
import { vr, IValid, ValidIdRequest } from '@/util/utils/validator'

export class SaveTargetTypeRequest extends IValid {
  remark: string = ''

  @vr({ required: true, min: 2, message: '引擎名称不能为空' })
  name: string = ''
}
export class EditTargetTypeRequest extends IValid implements IdEntity {
  @vr({ required: true, min: 0, message: 'ID不能为空', type: 'number' })
  id: number = 0

  remark: string = ''

  @vr({ required: true, min: 2, message: '引擎名称不能为空' })
  name: string = ''
}
export class QueryTargetTypeRequest extends PageRequest {
  @queryContent({ loadQuery: true })
  @queryText({ text: '引擎名称' })
  name: string = ''
}
export class QueryTargetTypeResponse implements IdEntity {
  @tableIdRow()
  id: number = 0

  @tableEditUrl({ header: '引擎名称', e: { click: 'edit' } })
  name: string = ''

  @tableEditUrl({ header: '引擎说明', e: { click: 'edit' } })
  remark: string = ''

  @tableDate({ header: '创建时间' })
  create_time: string = ''

  @tableDate({ header: '修改时间' })
  modify_time: string = ''
}
export class GetTargetTypeResponse implements IdEntity {
  id: number = 0

  @fromInput({ title: '引擎名称' })
  name: string = ''

  @areaInput({ title: '引擎说明', min: 2, maxlength: 1024 })
  remark: string = ''
}

export class TargetApi {
  @execPostValid('/vscode/target/save')
  static async save(request: SaveTargetTypeRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/vscode/target/edit')
  static async edit(request: EditTargetTypeRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/vscode/target/delete')
  static async delete(request: ValidIdRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @queryPost<QueryTargetTypeResponse>('/vscode/target/query')
  static async query(request: QueryTargetTypeRequest): Promise<PageResponse<QueryTargetTypeResponse>> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetTargetTypeResponse>('/vscode/target/getById', (resp) => resp ?? { id: 0 })
  static async getById(request: ValidIdRequest): Promise<GetTargetTypeResponse> {
    throw new EmptyHttpError()
  }
}

export const TargetViewObj: ApiWinModel<QueryTargetTypeRequest, QueryTargetTypeResponse> =
  new ApiWinModel(() => new QueryTargetTypeRequest(), () => new QueryTargetTypeResponse())
export const editModel = () => new GetTargetTypeResponse()
export const getModelTitle = (r: GetTargetTypeResponse | QueryTargetTypeResponse) => r.name
export const TargetOper: ISaveEditRequest<GetTargetTypeResponse, SaveTargetTypeRequest, EditTargetTypeRequest> = new ISaveEditRequest(
  {
    entity: () => new GetTargetTypeResponse(),
    save: () => new SaveTargetTypeRequest(),
    edit: () => new EditTargetTypeRequest()
  },
  {
    entity: TargetApi.getById,
    save: TargetApi.save,
    edit: TargetApi.edit,
    del: TargetApi.delete
  }
)
