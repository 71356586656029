/* eslint-disable camelcase */
import { queryText, queryContent, querySlot } from '@/util/models/admin/queryItem'
import { tableDate, tableEditUrl, tableIdRow, tableText, tableIcon, tableEnable, tableButton } from '@/util/models/admin/tableItem'
import { fromSlot, fromInput, areaInput, fromSwitch } from '@/util/models/admin/fromItem'
import { PageResponse, PageRequest, IdNameRemarkExEntity } from '@util/models/ServerBaseEntity'
import type { IdEntity } from '@util/models/ServerIdEntity'
import { adaptPostValid, ApiWinModel, queryPost, EmptyHttpError, execPostValid, ISaveEditRequest } from '@util/utils/HttpClient'
import { vr, IValid, ValidIdRequest } from '@/util/utils/validator'
import { getIconClass } from '@/util/utils/iconUtils'
import { dateFormat } from '@/util/utils/stringUtils'

export class SaveFileRequest extends IValid {
  @vr({ required: true, min: 0, message: '引擎类型不能为空', type: 'number' })
  type: number = 0

  @vr({ required: true, max: 200, message: '文件地址不能超过200个字符' })
  url: string = ''

  @vr({ required: true, max: 200, message: '文件名称不能超过200个字符' })
  name: string = ''

  @vr({ max: 1024, message: '文件说明不能超过1000个字符' })
  remark: string = ''

  format: number | undefined

  @vr({ required: true, min: 0, message: '公开类型不能为空', type: 'number' })
  auth_type: number = 0
}
export class EditFileRequest extends IValid implements IdEntity {
  @vr({ required: true, min: 0, message: 'ID不能为空', type: 'number' })
  id: number = 0

  @vr({ required: true, min: 0, message: '引擎类型不能为空', type: 'number' })
  type: number = 0

  @vr({ required: true, max: 200, message: '文件地址不能超过200个字符' })
  url: string = ''

  @vr({ required: true, max: 200, message: '文件名称不能超过200个字符' })
  name: string = ''

  @vr({ max: 1024, message: '文件说明不能超过1000个字符' })
  remark: string = ''

  format: number | undefined

  @vr({ required: true, min: 0, message: '公开类型不能为空', type: 'number' })
  auth_type: number = 0
}
export class QueryFileRequest extends PageRequest {
  @queryContent({ loadQuery: true })
  @queryText({ text: '文件名称' })
  name: string = ''

  @querySlot({ title: '引擎类型' })
  type: number | undefined

  @querySlot({ title: '公开类型' })
  auth_type: number | undefined
}
export class QueryFileResponse implements IdEntity {
  @tableButton({
    text: '导入',
    val: { position: 'header', width: 130 },
    style: { icon: getIconClass('el-icon-import'), type: undefined, size: 'default' },
    e: { click: 'import' }
  })
  @tableIdRow()
  id: number = 0

  @tableEditUrl({ header: '文件名称', e: { click: 'edit' } })
  name: string = ''

  @tableText({ header: '文件地址' })
  url: string = ''

  type: number = 0
  @tableText({ header: '引擎类型', width: 90 })
  type_name: string = ''

  format: number | undefined
  @tableText({ header: '格式化类型', width: 90 })
  format_name: string | undefined

  auth_type: number | undefined
  @tableText({ header: '公开类型', width: 90 })
  auth_name: string | undefined

  @tableDate({ header: '创建时间' })
  create_time: string = ''

  @tableDate({ header: '修改时间' })
  modify_time: string = ''
}
export class GetFileResponse implements IdEntity {
  id: number = 0

  @fromInput({ title: '文件名称' })
  name: string = ''

  @fromSlot({ title: '引擎类型' })
  type: string = ''

  @fromInput({ title: '文件地址' })
  url: string = ''

  @fromSlot({ title: '自定义格式化' })
  format: number | undefined

  @fromSlot({ title: '公开类型' })
  auth_type: number | undefined = 0

  @areaInput({ title: '文件说明', min: 3, maxlength: 1024 })
  remark: string = ''
}

export class FileApi {
  @execPostValid('/vscode/file/save')
  static async save(request: SaveFileRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/vscode/file/edit')
  static async edit(request: EditFileRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/vscode/file/delete')
  static async delete(request: ValidIdRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/vscode/file/import')
  static async import(request: SaveFileRequest[]): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @queryPost<QueryFileResponse>('/vscode/file/query')
  static async query(request: QueryFileRequest): Promise<PageResponse<QueryFileResponse>> {
    throw new EmptyHttpError()
  }

  @queryPost<IdNameRemarkExEntity>('/vscode/file/queryIdName')
  static async queryIdName(request: PageRequest): Promise<PageResponse<IdNameRemarkExEntity>> {
    throw new EmptyHttpError()
  }

  @adaptPostValid<GetFileResponse>('/vscode/file/getById', (resp) => resp ?? { id: 0 })
  static async getById(request: ValidIdRequest): Promise<GetFileResponse> {
    throw new EmptyHttpError()
  }
}

export function FileAdapt(index: number, req: { key: string, val: any }, type: number, auth_type: number, type_name: string, auth_type_name: string) {
  const tmp: any = new GetFileResponse()
  tmp.id = index
  tmp.name = req.key
  tmp.url = req.val.url
  tmp.format = req.val?.format
  tmp.remark = req.val?.desc ?? req.val?.remark ?? ''
  tmp.type = type
  tmp.auth_type = auth_type
  tmp.type_name = type_name
  tmp.auth_name = auth_type_name
  tmp.create_time = dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss')
  tmp.modify_time = dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss')
  return tmp
}

export const FileViewObj: ApiWinModel<QueryFileRequest, QueryFileResponse> =
  new ApiWinModel(() => new QueryFileRequest(), () => new QueryFileResponse())
export const editModel = () => new GetFileResponse()
export const getModelTitle = (r: GetFileResponse | QueryFileResponse) => r.name
export const FileOper: ISaveEditRequest<GetFileResponse, SaveFileRequest, EditFileRequest> = new ISaveEditRequest(
  {
    entity: () => new GetFileResponse(),
    save: () => new SaveFileRequest(),
    edit: () => new EditFileRequest()
  },
  {
    entity: FileApi.getById,
    save: FileApi.save,
    edit: FileApi.edit,
    del: FileApi.delete
  }
)
